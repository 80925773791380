import { Link } from "theme-ui";
import { faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from 'react';
export default {
  Link,
  faLinkedinIn,
  faTwitter,
  faEnvelope,
  FontAwesomeIcon,
  React
};